import React from 'react'
import './footer.scss';
class Footer extends React.Component {
    render() {
        return <h1>Footer </h1>
    }
}

export default Footer;


